// extracted by mini-css-extract-plugin
export var cloudAppDevBenefitsSection = "S_hR";
export var cloudAppDevBusinessSection = "S_hL";
export var cloudAppDevCasesSection = "S_hS";
export var cloudAppDevIndustriesSection = "S_hM";
export var cloudAppDevModelSection = "S_hK";
export var cloudAppDevOurAchievementsSection = "S_hQ";
export var cloudAppDevPrimeSection = "S_hH";
export var cloudAppDevProcessSection = "S_hN";
export var cloudAppDevQuotesSection = "S_hT";
export var cloudAppDevServicesSection = "S_hJ";
export var cloudAppDevTechStackSection = "S_hP";